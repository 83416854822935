import React, { Component } from 'react';

class NotFound extends Component {
    componentDidMount() {
        // Scrollt zur obersten Position der Seite, wenn die Komponente gemountet wird
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Seite nicht gefunden</h1>
            </>
        );
    }
}

export default NotFound;
