import { Component, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SocialMediaContainer from './SocialMediaContainer';
import { NavLink } from 'react-router-dom';
import { faReact, faWordpressSimple } from '@fortawesome/free-brands-svg-icons';

interface Item {
    text: string | undefined;
    href: string;
    icon?: ReactNode;
    blank?: boolean;
}

interface FooterItem {
    heading: string;
    itemList: Item[];
}

interface FooterState { }

class Footer extends Component<{}, FooterState> {
    items: FooterItem[] = [
        {
            heading: 'Leistungen',
            itemList: [
                {
                    text: 'WordPress Webseite',
                    icon: <FontAwesomeIcon icon={faWordpressSimple} style={{ color: "#ffffff" }} />,
                    href: '/services#card-wordpress'
                },
                {
                    text: 'Individuelle Webseite',
                    icon: <FontAwesomeIcon icon={faReact} style={{ color: "#ffffff" }} />,
                    href: '/services#card-individual'
                },
            ]
        },
        {
            heading: 'Kontakt',
            itemList: [
                /*{
                    text: process.env.REACT_APP_ADDRESS,
                    icon: <FontAwesomeIcon icon={faLocationDot} />,
                    href: `http://maps.google.com/?q=${process.env.REACT_APP_ADDRESS}`,
                    blank: true
                },
                {
                    text: process.env.REACT_APP_PHONE,
                    icon: <FontAwesomeIcon icon={faPhone} style={{ color: "#ffffff" }} />,
                    href: `tel: ${process.env.REACT_APP_PHONE}`,
                    blank: false
                },*/
                {
                    text: process.env.REACT_APP_EMAIL,
                    icon: <FontAwesomeIcon icon={faEnvelope} style={{ color: "#ffffff" }} />,
                    href: `mailto: ${process.env.REACT_APP_EMAIL}`,
                    blank: false
                }
            ]
        },
        {
            heading: 'Rechtliches',
            itemList: [
                {
                    text: 'Impressum',
                    href: 'impressum'
                },
                {
                    text: 'Datenschutz',
                    href: 'datenschutz'
                }
            ]
        }
    ];

    render() {
        return (
            <footer className={`footer flex flex-col gap-20 sm:gap-14 px-4 md:px-12 py-8 bg-gray-800`}>
                <div className='flex justify-around flex-col md:flex-row gap-14 md:gap-0'>
                    {this.items.map((footerItem, index) => (
                        <div key={index} className="footer-list flex flex-col gap-4 md:gap-6">
                            <div className="footer-list-heading text-2xl text-white text-center">{footerItem.heading}</div>
                            <div className="footer-list-items flex items-center flex-col gap-4">
                                {footerItem.itemList.map((item, index) => (
                                    <div key={index} className="footer-list-item flex">
                                        {this.getLink(item)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex justify-center items-center gap-14 sm:gap-40 flex-col sm:flex-row'>
                    <SocialMediaContainer />
                    <div className='text-white flex items-center justify-center'>{`© ${new Date().getFullYear()} Marvin Klare`}</div>
                </div>
            </footer>
        );
    }

    getLink(item: Item) {
        if (item.blank) {
            return <a target='_blank' rel='noreferrer' href={item.href} className="text-gray-200 hover:text-gray-300 flex gap-3 justify-center items-center">{item.icon} {item.text}</a>;
        } else {
            return <NavLink to={item.href} className="text-gray-200 hover:text-gray-300 flex gap-3 justify-center items-center">{item.icon} {item.text}</NavLink>;;
        }
    }
}

export default Footer;
