import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App'; // Importiere die Komponenten

const AppWrapper: React.FC = () => {
    return <App />;
}

// Typisierung für die Root-Komponente ist nicht unbedingt erforderlich,
// da sie keine Props hat.
const Root: React.FC = () => (
    <Router>
        <AppWrapper />
    </Router>
);

export default Root;
