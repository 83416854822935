import { Component } from 'react';

interface PortfolioProps {

}

interface PortfolioState {

}

class Portfolio extends Component<PortfolioProps, PortfolioState> {
    state = {};

    render() {
        return <>
            <div>
                Mein Portfolio.
            </div>
        </>;
    }
}

export default Portfolio;