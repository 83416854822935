import React, { Component } from 'react';

interface CardProps {
    id: string | undefined;
    img?: string | null;
    imgAlt?: string | undefined;
    icon: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
    button: React.ReactNode
}

interface CardState { }

class Card extends Component<CardProps, CardState> {
    state = {};

    render() {
        return (
            <div id={this.props.id} className='bg-white overflow-hidden rounded-2xl shadow-lg shadow-gray-400 w-full min-w-72 max-w-96 border border-solid border-gray-300'>
                {this.props.img ?
                    <img src={this.props.img} alt={this.props.imgAlt} className="card-image w-full h-auto block" /> :
                    this.props.icon ?? ''
                }

                <div className='p-4'>
                    <h2 className="mb-12 text-3xl text-gray-700">{this.props.title}</h2>
                    <div className="mb-12 text-gray-700">{this.props.description}</div>
                    {this.props.button}
                </div>
            </div>
        );
    }
}

export default Card;