import React from 'react';
import Button from './Button';
import { useNavigate } from 'react-router-dom';

const Hero: React.FC = () => {

    const navigate = useNavigate(); // Hook zum Navigieren

    const click_Services = () => {
        navigate('/services');
    };

    const click_AboutMe = () => {
        navigate('/about');
    };

    /*const scrollToContent = (): void => {
        const element = document.getElementById('page-content');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };*/


    return (
        <div className="hero bg-hero-image h-screen bg-cover bg-center w-full relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 before:backdrop-brightness-50">
            <div className='hero-content px-8 pb-20 h-full flex flex-col justify-center items-center relative z-10 gap-10'>
                <div className='text-white text-2xl sm:text-4xl text-center tracking-wider'>Willkommen bei</div>
                <h1 className='text-white text-5xl sm:text-7xl font-bold text-center tracking-wide uppercase'>Klare Software</h1>
                <div className='w-3/4 sm:w-1/2 lg:w-1/3 h-1 border-t-2 border-gray-300'></div>
                <div className='text-white text-center text-2xl sm:text-4xl tracking-wider'>
                    Wir haben den Durchblick
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 mt-12 sm:text-4xl font-bold flex flex-col sm:flex-row items-center justify-center gap-4" >
                    <Button text="Über mich" onClick={click_AboutMe} className='min-w-fit text-lg' />
                    <Button text="Leistungen" onClick={click_Services} className='min-w-fit text-lg' />
                </div>
            </div>
        </div>
    );
}

export default Hero;
