import React, { Component } from 'react';
import ContactForm from '../components/ContactForm';

interface ContactProps { }

interface ContactState { }

class Contact extends Component<ContactProps, ContactState> {
    componentDidMount() {
        // Scrollt zur obersten Position der Seite, wenn die Komponente gemountet wird
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Kontakt</h1>
                <ContactForm />
            </>
        );
    }
}

export default Contact;
