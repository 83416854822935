import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '../components/Card';
import Button from '../components/Button';
import { faReact, faWordpressSimple } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

// Interface für die Struktur der Card-Daten
interface CardData {
    id: string,
    icon: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
}

const Services: React.FC = () => {
    const navigate = useNavigate(); // Hook zum Navigieren

    const handleButtonClick = () => {
        navigate('/contact');
    };

    useEffect(() => {
        // Extrahiere das Fragment (Hash) aus der URL
        const hash = window.location.hash;

        if (hash) {
            // Scrollt zur Position, die im Fragment angegeben ist
            const element = document.querySelector(hash);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                const offset = -50; // Hier kannst du den Offset einstellen, z.B. -50px
                window.scrollTo({
                    top: elementPosition + offset,
                    behavior: 'smooth' // Für sanftes Scrollen
                });
            }
        }
    }, []);

    // Die cards-Daten im Typ CardData[]
    const cards: CardData[] = [
        {
            id: "card-wordpress",
            icon: <FontAwesomeIcon icon={faWordpressSimple} className='w-full h-20 text-gray-800 py-4' />,
            title: <div className='text-center font-bold'>WordPress<br />Lösung</div>,
            description:
                <div className='pl-6 pr-4 pt-6'>
                    <ul className='flex flex-col gap-4 text-lg'>
                        <li className='list-disc'>Auswahl und Anpassung von WordPress-Themes</li>
                        <li className='list-disc'>Einpflegen von Inhalten wie Texten und Bildern</li>
                        <li className='list-disc'>Integration von Plugins für erweiterte Funktionalitäten</li>
                        <li className='list-disc'>SEO-Optimierung</li>
                    </ul>
                </div>
        },
        {
            id: "card-individual",
            icon: <FontAwesomeIcon icon={faReact} className='w-full h-20 text-gray-800 py-4' />,
            title: <div className='text-center font-bold'>Individuelle<br />Lösung</div>,
            description:
                <div className='pl-6 pr-4'>
                    <ul className='flex flex-col gap-4 text-lg'>
                        <li className='list-disc'>
                            <div className='flex flex-wrap sm:inline '>
                                <span className='font-bold whitespace-nowrap'>HTML:</span>&nbsp;
                                <span>Strukturierte und semantische Markup-Lösungen für eine solide Grundlage deiner Website</span>
                            </div>
                        </li>
                        <li className='list-disc'>
                            <div className='flex flex-wrap sm:inline '>
                                <span className='font-bold whitespace-nowrap'>CSS/Tailwind CSS:</span>&nbsp;
                                <span>Stilvolle und responsive Designs mit maßgeschneiderten Stilen und schnellen Ladezeiten</span>
                            </div>
                        </li>
                        <li className='list-disc'>
                            <div className='flex flex-wrap sm:inline '>
                                <span className='font-bold'>JavaScript/TypeScript:</span>&nbsp;
                                <span>Dynamische und interaktive Webanwendungen mit den neuesten Technologiestandards</span>
                            </div>
                        </li>
                        <li className='list-disc'>
                            <div className='flex flex-wrap sm:inline '>
                                <span className='font-bold whitespace-nowrap'>React:</span>&nbsp;
                                <span>Moderne, performante und skalierbare Frontend-Lösungen mit wiederverwendbaren Komponenten</span>
                            </div>
                        </li>
                        <li className='list-disc'>
                            <div className='flex flex-wrap sm:inline '>
                                <span className='font-bold whitespace-nowrap'>Node.js:</span>&nbsp;
                                <span>Effiziente und schnelle Backend-Entwicklung für serverseitige Anwendungen</span>
                            </div>
                        </li>
                        <li className='list-disc'>
                            <div className='flex flex-wrap sm:inline '>
                                <span className='font-bold whitespace-nowrap'>MySQL:</span>&nbsp;
                                <span>Zuverlässige und skalierbare Datenbanklösungen für deine Webprojekte</span>
                            </div>
                        </li>
                    </ul>
                </div>
        }
    ];

    return (
        <>
            <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Leistungen</h1>
            <div className="text-center mb-12 text-lg leading-relaxed text-gray-700">
                <p className="mb-4">Erstellung und Gestaltung einer benutzerfreundlichen WordPress-Webseite, die leicht zu verwalten und anzupassen ist?</p>
                <p className="mb-4">Oder doch eine maßgeschneiderte Webseite unter Verwendung moderner Webtechnologien, die spezifisch auf deine Bedürfnisse und Anforderungen zugeschnitten ist?</p>
                <p>Zusammen finden wir die perfekte Lösung!</p>
            </div>
            <div className="cards flex flex-wrap justify-center gap-12 w-full">
                {cards.map((card) => (
                    <Card
                        key={card.id}
                        id={card.id}
                        icon={card.icon}
                        title={card.title}
                        description={card.description}
                        button={<Button text="Mehr erfahren" className="primary font-bold" onClick={handleButtonClick} />}
                    />
                ))}
            </div>
        </>
    );
};

export default Services;
