import React, { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';

interface FormData {
    name: string;
    email: string;
    message: string;
    subject: string;
    birthday: string;
}

interface Errors {
    name?: string;
    email?: string;
    message?: string;
    subject?: string;
}

const ContactForm: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        message: '',
        subject: '',
        birthday: ''
    });
    const [errors, setErrors] = useState<Errors>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successfulSent, setSuccessfulSent] = useState<boolean | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = (): Errors => {
        const newErrors: Errors = {};
        if (!formData.name) newErrors.name = 'Name ist erforderlich';
        if (!formData.email) {
            newErrors.email = 'E-Mail ist erforderlich';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'E-Mail ist ungültig';
        }
        if (!formData.message) newErrors.message = 'Nachricht ist erforderlich';
        if (!formData.subject) newErrors.subject = 'Betreff ist erforderlich';
        return newErrors;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setSuccessfulSent(null);
        setIsSubmitting(true);
        try {
            // Daten formatieren für URLSearchParams
            const params = new URLSearchParams();
            for (const key in formData) {
                if (formData[key as keyof FormData]) {
                    params.append(key, formData[key as keyof FormData]);
                }
            }

            let response = await axios.post(
                `${process.env.REACT_APP_BASEURL}/sendMessage`,
                params.toString(),
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            );
            if (response.status >= 200 && response.status < 300) { // assuming response has a data field with success property
                setSuccessfulSent(true);
                setFormData({ name: '', email: '', message: '', subject: '', birthday: '' });
                setErrors({});
            } else {
                setSuccessfulSent(false);
            }
        } catch (error) {
            setSuccessfulSent(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow-md w-full md:w-3/4">
            <form onSubmit={handleSubmit}>
                <input
                    className='absolute -z-10'
                    type="text"
                    name="birthday"
                    id="birthday"
                    value={formData.birthday}
                    onChange={handleChange}
                    placeholder='Birthday'
                />
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : ''
                            }`}
                        placeholder="Name"
                    />
                    {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        E-Mail
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.email ? 'border-red-500' : ''
                            }`}
                        placeholder="E-Mail-Adresse"
                    />
                    {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                        Betreff
                    </label>
                    <input
                        type="text"
                        name="subject"
                        id="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.subject ? 'border-red-500' : ''
                            }`}
                        placeholder="Betreff"
                    />
                    {errors.subject && <p className="text-red-500 text-xs italic">{errors.subject}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                        Nachricht
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.message ? 'border-red-500' : ''
                            }`}
                        placeholder="Nachricht"
                        rows={4}
                    />
                    {errors.message && <p className="text-red-500 text-xs italic">{errors.message}</p>}
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className={`bg-gray-800 hover:bg-gray-700 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Senden...' : 'Senden'}
                    </button>
                </div>
            </form>
            {successfulSent === true && (
                <div className="mt-4 text-green-500 font-bold">
                    Nachricht erfolgreich gesendet!
                </div>
            )}
            {successfulSent === false && (
                <div className="mt-4 text-red-500 font-bold">
                    Fehler beim Senden der Nachricht.
                </div>
            )}
        </div>
    );
};

export default ContactForm;
