import React, { Component } from 'react';

class Home extends Component {
    state = {};

    render() {
        return <>
            Professionelle Websites durch fortschrittliche Softwarelösungen
        </>;
    }
}

export default Home;