import React, { Component } from 'react';

interface ImpressumProps {

}

interface ImpressumState {

}

class Impressum extends Component<ImpressumProps, ImpressumState> {
    state = {};

    getStyle() {
        return <>
            <style>
                {`
                    h1 {
                        font-size: 2rem;
                        margin-bottom: 40px;
                    }
                    h2 {
                        font-size: 1.5rem;
                        margin-top: 40px;
                        margin-bottom: 8px;
                    }
                `}
            </style>
        </>
    }
    render() {
        return <>
            {this.getStyle()}
            <div>
                <h1>Impressum</h1>

                <p>Marvin Klare<br />
                    Klare Software<br />
                    Pottkamp 6<br />
                    48565 Steinfurt</p>

                <h2>Kontakt</h2>
                <p>E-Mail: kontakt@klare-software.de</p>

                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br /></p>

                <h2>EU-Streitschlichtung</h2>
                <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
            </div>
        </>;
    }
}

export default Impressum;