import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedinIn, faXing } from '@fortawesome/free-brands-svg-icons';

interface SocialMediaProps {
    className?: string;
}

const SocialMediaContainer: React.FC<SocialMediaProps> = (props) => {
    return (
        <div className={`flex gap-8 ${props.className}`}>
            <a
                href={`https://www.instagram.com/${process.env.REACT_APP_INSTAGRAM}`}
                target='_blank'
                rel='noreferrer'
            >
                <FontAwesomeIcon icon={faInstagram} className='text-white h-8 w-8' />
            </a>
            <a
                href={`https://www.linkedin.com/in/${process.env.REACT_APP_LINKEDIN}`}
                target='_blank'
                rel='noreferrer'
            >
                <FontAwesomeIcon icon={faLinkedinIn} className='text-white h-8 w-8' />
            </a>
            <a
                href={`https://www.xing.com/profile/${process.env.REACT_APP_XING}`}
                target='_blank'
                rel='noreferrer'
            >
                <FontAwesomeIcon icon={faXing} className='text-white h-8 w-8' />
            </a>
        </div>
    );
};

export default SocialMediaContainer;
