import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import Button from './Button';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // Überprüfen, ob der Benutzer bereits zugestimmt hat
        const cookieConsent = Cookies.get('cookieConsent');
        if (!cookieConsent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'true', { expires: 365 }); // Cookie für 1 Jahr setzen
        setShowBanner(false);
    };

    return (
        showBanner && (
            <div className="z-50 fixed bottom-0 left-0 w-full bg-gray-900 text-white px-4 md:px-8 py-4 flex gap-4 flex-col sm:flex-row justify-between items-center">
                <span>Wir verwenden Cookies, um dir eine bessere Erfahrung bieten zu können. Wenn du diese Website weiterhin nutzt, stimmst du der Verwendung von Cookies zu.</span>
                <div>
                    <Button text="Akzeptieren" onClick={handleAccept}></Button>
                </div>
            </div>
        )
    );
};

export default CookieBanner;
