import React, { Component } from 'react';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

interface State {
    isVisible: boolean;
}

class ScrollToTop extends Component<{}, State> {
    state: State = { isVisible: false };

    render() {
        return (
            this.state.isVisible &&
            <div className="scrollToTop z-50 fixed bottom-5 right-5" onClick={this.scrollToTop}>
                <Button className={`bg-gray-700 text-white`} text={<FontAwesomeIcon icon={faArrowUp} className='h-6 w-6' />} />
            </div>
        );
    }

    toggleVisibility = () => {
        if (window.scrollY > 300) {
            this.setState({ isVisible: true });
        } else {
            this.setState({ isVisible: false });
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.toggleVisibility);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.toggleVisibility);
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
}

export default ScrollToTop;
