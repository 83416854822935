import React, { Component } from 'react';
import logo from "../assets/img/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialMediaContainer from './SocialMediaContainer';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

interface NavItem {
    href: string;
    text: string;
    children?: NavItem[];
}

interface NavbarState {
    isOpen: boolean;
}

class Navbar extends Component<{}, NavbarState> {
    state: NavbarState = {
        isOpen: false
    };

    items: NavItem[] = [
        { href: '/', text: 'Startseite' },
        { href: 'about', text: 'Über mich' },
        //{ href: 'portfolio', text: 'Portfolio' },
        { href: 'services', text: 'Leistungen' },
        { href: 'contact', text: 'Kontakt' }
    ];

    handleNavClick = () => {
        if (this.state.isOpen)
            this.toggleNavbar();
    };

    render() {
        return (
            <nav className={`navbar z-20 flex justify-between items-center h-16 relative text-white bg-gray-800 shadow-lg px-2 md:px-8`}>
                <a className="navbar-brand h-full py-1 flex items-center ml-2 md:ml-0" href="/">
                    <img src={logo} className="h-1/2 lg:h-3/5" alt="logo" />
                </a>
                <ul className={`navbar-items translate-x-100 md:translate-x-0 max-w-[60%] md:max-w-fit w-[60%] md:w-fit fixed md:relative top-0 right-0 bottom-0 pt-16 flex-col md:flex-row md:p-0 bg-gray-800 z-30 flex h-full transition-all duration-300 ease ${this.state.isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    {this.state.isOpen &&
                        <button className="z-10 cursor-pointer absolute right-4 top-5" onClick={this.toggleNavbar}>
                            <span className="navbar-toggle-icon">
                                <FontAwesomeIcon icon={faXmark} className='h-6 w-6' />
                            </span>
                        </button>
                    }
                    {this.renderItems(this.items)}

                    {this.state.isOpen && <SocialMediaContainer className="absolute bottom-2 w-full justify-center" />}
                </ul>
                <button className="navbar-toggle cursor-pointer bg-none border-none text-white flex mr-2 text-2xl md:hidden" onClick={this.toggleNavbar}>
                    <span className="navbar-toggle-icon text-white text-center h-6 w-6 inline-block leading-6">
                        <FontAwesomeIcon icon={faBars} />
                    </span>
                </button>
                <div className={`overlay z-20 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 ${this.state.isOpen ? 'active block' : 'hidden'}`} onClick={this.toggleNavbar}></div>
            </nav>
        );
    }

    renderItems = (items: NavItem[]) => {
        return items.map((item, index) => (
            <li key={index} className='relative h-fit md:h-full flex items-center justify-center group '>
                <NavLink to={item.href} onClick={this.handleNavClick} className={({ isActive }) =>
                    `${isActive ? 'bg-gray-700' : 'bg-gray-800'} py-3 md:py-0 md:shadow-lg w-full text-white hover:bg-gray-700 transition-colors duration-200 ease-in text-lg md:text-sm lg:text-base h-full flex items-center px-6`
                }>
                    {item.text.toUpperCase()}
                </NavLink>
                {item.children && (
                    <ul className="submenu hidden absolute top-full left-0 p-0 z-50 group-hover:block">
                        {this.renderItems(item.children)}
                    </ul>
                )}
            </li>
        ));
    };

    toggleNavbar = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };
}

export default Navbar;
