import React, { Component } from 'react';
import Timeline from '../components/Timeline';

interface AboutProps {

}

interface AboutState {

}

class About extends Component<AboutProps, AboutState> {
    state = {};

    render() {
        return <>
            <div className='lg:w-2/3'>
                <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Über mich</h1>
                <div className='mb-8 italic text-lg text-center'>Mit der Leidenschaft fürs Programmieren und Entwickeln von Software <br></br>in Steinfurt, Münster und Umgebung.</div>
                <h2 className='text-2xl text-gray-800 mb-8 text-center'>Mein Ansatz</h2>
                <div className='text-lg text-gray-700'>
                    Bei jedem Projekt lege ich großen Wert auf Qualität und Benutzerfreundlichkeit.
                    Ich arbeite eng mit meinen Kunden zusammen, um ihre Anforderungen und Wünsche genau zu verstehen
                    und maßgeschneiderte Lösungen zu entwickeln, die ihren individuellen Bedürfnissen entsprechen.
                </div>
                <h2 className='text-2xl text-gray-800 my-8 text-center'>Mein Werdegang</h2>
                <Timeline />
            </div>
        </>;
    }
}

export default About;