import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import "../src/style/tailwind.css";
import "../src/style/scss/index.scss";

//pages
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import Contact from './pages/Contact';
import Services from './pages/Services';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import NotFound from './pages/NotFound';

//components
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Hero from './components/Hero';
import CookieBanner from './components/CookieBanner';

const App: React.FC = () => {
    const location = useLocation();
    const isHomeRoute = location.pathname === '/';

    return (
        <>
            <Navbar />
            <div className='flex-grow'>
                {isHomeRoute && (
                    <Hero />
                )}
                <div id='page-content' className={isHomeRoute ? 'hidden' : `flex flex-col items-center justify-center gap-10 p-4 md:p-10 bg-gray-100`}>
                    <div className='w-full sm:w-3/4 xl:w-2/3 flex flex-col justify-center items-center gap-8 py-4'>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/impressum" element={<Impressum />} />
                            <Route path="/datenschutz" element={<Datenschutz />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/portfolio" element={<Portfolio />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                </div>
            </div>
            <Footer />
            <ScrollToTop />
            <CookieBanner />
        </>
    );
}

export default App;
