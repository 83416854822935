import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faClock, faGraduationCap, faLaptopCode } from '@fortawesome/free-solid-svg-icons';

// Interface für die Struktur der Event-Daten
interface Event {
    title: React.ReactNode; // React.ReactNode erlaubt sowohl Strings als auch JSX
    icon: React.ReactNode;
    date: string;
    dateIcon: React.ReactNode;
    description?: React.ReactNode; // Beschreibung ist optional
}

const Timeline: React.FC = () => {
    const events: Event[] = [
        // Beispiel-Daten
        {
            title: 'Bachelor of Science (B.Sc.)',
            icon: <FontAwesomeIcon icon={faGraduationCap} className='mr-2' />,
            date: '2015 - 2018',
            dateIcon: <FontAwesomeIcon icon={faClock} className="mr-2" />,
            description: (
                <div className="flex flex-col gap-2">
                    <span>Studiengang: Medieninformatik</span>
                    <span className='italic'>
                        Entwurf und Implementierung einer intuitiven
                        Web-Benutzeroberfläche <br className='hidden md:block'></br> für die Erfassung und
                        Verarbeitung von Dienstplänen
                    </span>
                </div>
            )
        },
        {
            title: 'Master of Science (M.Sc.)',
            icon: <FontAwesomeIcon icon={faGraduationCap} className='mr-2' />,
            date: '2019 - 2020',
            dateIcon: <FontAwesomeIcon icon={faClock} className="mr-2" />,
            description: (
                <div className="flex flex-col gap-2">
                    <span>Studiengang: Medieninformatik</span>
                    <span className='italic'>
                        Elemente einer modernen User Experience in
                        individuellen unternehmensinternen
                        browserbasierten Informationssystemen
                    </span>
                </div>
            )
        },
        {
            title: <span>Software&shy;entwickler</span>,
            icon: <FontAwesomeIcon icon={faLaptopCode} className='mr-2' />,
            date: 'seit 2021',
            dateIcon: <FontAwesomeIcon icon={faClock} className="mr-2" />,
            description: 'Full Stack Entwickler'
        },
        {
            title: <span>Selbst&shy;ständigkeit</span>,
            icon: <FontAwesomeIcon icon={faBriefcase} className='mr-2' />,
            date: 'seit 2024',
            dateIcon: <FontAwesomeIcon icon={faClock} className="mr-2" />,
            description: 'Klare Software'
        }
    ];

    return (
        <div className="relative px-4 py-8">
            <div className="absolute border-l-2 border-gray-700 left-8 top-0 h-full"></div>
            <div className="space-y-8">
                {events.map((event, index) => (
                    <div key={index} className="flex items-center">
                        <div className="w-8 h-8 min-w-8 min-h-8 bg-gray-700 rounded-full relative flex items-center justify-center">
                            <span className="w-6 h-6 bg-white rounded-full"></span>
                        </div>
                        <div className="ml-8 sm:ml-16 p-4 bg-white shadow-lg grow rounded-md">
                            <h3 className="flex gap-4 sm:gap-2 items-center text-lg font-semibold text-gray-800 mb-2">
                                {event.icon}
                                {event.title}
                            </h3>
                            <div className="flex items-center gap-2 text-gray-600">
                                {event.dateIcon}
                                {event.date}
                            </div>
                            {event.description && <div className="text-gray-700 mt-2">{event.description}</div>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Timeline;
