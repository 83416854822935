import React, { Component } from 'react';

interface ButtonProps {
    text: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

interface ButtonState { }

class Button extends Component<ButtonProps, ButtonState> {
    state: ButtonState = {};

    render() {
        const btnClasses = "box-border cursor-pointer w-full py-2 px-4 flex items-center justify-center border border-transparent text-base font-medium rounded-md md:py-2 md:text-lg md:px-5";
        const { className, text } = this.props;

        if (className?.includes('primary')) {
            return (
                <div className={`bg-gray-800 text-gray-100 hover:text-gray-200 hover:bg-gray-700 ${btnClasses} ${className}`} onClick={this.handleClick}>
                    {text}
                </div>
            );
        } else if (className?.includes('bg-')) {
            return (
                <div className={`${className} ${btnClasses}`} onClick={this.handleClick}>
                    {text}
                </div>
            );
        } else {
            return (
                <div className={`bg-white text-gray-800 hover:text-gray-900 hover:bg-gray-200 ${btnClasses} ${className}`} onClick={this.handleClick}>
                    {text}
                </div>
            );
        }
    }

    handleClick = () => {
        if (typeof this.props.onClick === "function") {
            this.props.onClick();
        }
    };
}

export default Button;
